const navigationAnimation = () => {
    let isCollapsed = true;
    const tl = gsap.timeline();
    const tl1 = gsap;

    const burger = ".navigation__btn";
    const nav = ".navigation__list";
    const navWrap = ".navigation__wrapper";
    const navAction = (play=1) => {
        if(play==1){
            tl1.to(navWrap, {
                maxWidth: 0,
                duration: .1,
            })
            tl1.to(burger, {
                width: "4rem",
                duration: .1,
                opacity: 1,
            });
        }else{
            tl1.to(navWrap, {
                maxWidth: "700px",
                duration: .1,
            })
            tl1.to(burger, {
                width: 0,
                duration: .1,
                opacity: 0.5,
            });
        }
        
    }
    const buttonHandler = (e) => {
        if (isCollapsed) {
            navAction(2);
            isCollapsed = false;
        }
    }

    document.addEventListener("mousemove", (e) => {
        if (!e.target.closest("nav")) {
            if (!isCollapsed && scrollY > 100) {
                setTimeout(() => {
                    navAction(1);
                }, 2000);
                isCollapsed = true;
            }
        }
    });

    document.querySelector(burger).addEventListener("click", buttonHandler);
    ScrollTrigger.create({
        start: '100px top',
        end: 99999,
        toggleActions: "",
        onToggle: self => {
            if (self.isActive) {
                navAction(1);
            } else {
                navAction(2);
            }
            isCollapsed = self.isActive;
        },
    });
}
const revealHeading = () => {
    const tl = gsap.timeline();
    tl.to(".reveal-text", {
        duration: 1.5,
        y: "-100%",
        stagger: {
            each: -1
        }
    });
}
const revealAnimation = () => {
    const boxes = gsap.utils.toArray('.reveal');
    boxes.forEach((box, i) => {
        const anim = gsap.from(box, {maxHeight: 0, duration: 1, ease: "power3.in"});
        ScrollTrigger.create({
            trigger: box,
            animation: anim,
            toggleActions: 'restart restart restart none',
        });
    });
}
const activeNav = () => {
    const navLinks = gsap.utils.toArray(".navigation__item");
    const sections = gsap.utils.toArray("section");
    const asides = gsap.utils.toArray("aside");
    const fields = gsap.utils.toArray(".field");
    const footer = gsap.utils.toArray("footer");
    const fullSections = [...sections, ...asides, ...fields, ...footer];

    fullSections.forEach(section => {
        if (section.hasAttribute("data-section")) {
            ScrollTrigger.create({
                trigger: section,
                start: "top center",
                end: "bottom center",
                onToggle: self => {
                    let currentSection = section.getAttribute("data-section");
                    let currentLink = document.querySelector(`a[href="#${currentSection}"]`);
                    currentLink.classList.toggle("active");
                },
            });
        }
    });
}


const talents = () => {
    const tl = gsap.timeline();
    const anim = tl.fromTo(".talents__content h2", {opacity: 0}, {
        opacity: 1,
        duration: .5
    }).fromTo(".talents__content p", {opacity: 0}, {opacity: 1, duration: .3});

    ScrollTrigger.create({
        trigger: ".talents__content",
        animation: anim,
        start: "top 50%",
        toggleClass: "active",
        toggleActions: "play none none reverse"
    });
}
const aboutDarked = () => {
    ScrollTrigger.create({
        trigger: ".about",
        start: "top 50%",
        end: "bottom center",
        toggleClass: "active",
        toggleActions: "play restart none reverse"
    });
}
const slotMachine = () => {
    let rollers = gsap.utils.toArray(".roller"),
        currentRoller = rollers[0],
        slot = document.querySelector(".slot");

    gsap.defaults({overwrite: 'auto', duration: 0.3});
    gsap.set(".slot-container", {height: (rollers.length * 100) + "vh"});
    rollers.length
    rollers.forEach((roller, i) => {
        // console.log(roller.id)
        ScrollTrigger.create({
            start: () => (i - 0.5) * innerHeight + slot.offsetTop,
            end: () => (i + 0.5) * innerHeight + slot.offsetTop,
            onToggle: self => {
                if (self.isActive) {
                    setRoller(roller);
                    updateContent(roller.id);
                }
            }
            // onEnter: self => updateContent(roller.id)
            // onUpdate: self =>
        });
    });

    const updateContent = (currentRoller) => {
        const texts = [...document.getElementsByClassName("slot__text-item")];
        let attrib;
        texts.forEach(text => {
            let el = document.querySelector(`[data-slot=${attrib}]`);
            attrib = text.getAttribute("data-slot");
            if (currentRoller === attrib) {
                text.classList.add("active");
                [...text.children].forEach(kid => {
                    if (kid.classList.contains("slot__text--description")) {
                        gsap.to(kid, {
                            height: "auto",
                            duration: 0.5,
                            ease: "power1.inOut"
                        });
                    }
                });
            } else {
                text.classList.remove("active");
                [...text.children].forEach(kid => {
                    if (kid.classList.contains("slot__text--description")) {
                        gsap.to(kid, {
                            height: 0,
                            duration: 0.5,
                            ease: "power1.inOut"
                        });
                    }
                });
            }
        });
    }


// https://codepen.io/GreenSock/pen/GqKrxG
// https://codepen.io/StephenScaff/pen/oLBqmw
    function setRoller(newRoller) {
        if (newRoller !== currentRoller) {
            // gsap.to(currentRoller, { autoAlpha: 0})
            currentRoller.querySelector("video").pause();
            newRoller.querySelector("video").play();
            gsap.to(currentRoller, {autoAlpha: 0, duration: 1});
            gsap.to(newRoller, {autoAlpha: 1, duration: .5});
            currentRoller = newRoller;
        }
    }
}
const slotMachineIndustries = () => {
    let rollers = gsap.utils.toArray(".roller-industries"),
        currentRoller = rollers[0],
        slot = document.querySelector(".slot-industries");

    gsap.defaults({overwrite: 'auto', duration: 0.3});
    gsap.set(".slot-container-industries", {height: (rollers.length * 100) + "vh"});

    rollers.forEach((roller, i) => {
        // console.log(roller.id)
        ScrollTrigger.create({
            start: () => (i - 0.5) * innerHeight + slot.offsetTop,
            end: () => (i + 0.5) * innerHeight + slot.offsetTop,
            onToggle: self => {
                if (self.isActive) {
                    setRoller(roller);
                    updateContent(roller.id);
                }
            }
            // onEnter: self => updateContent(roller.id)
            // onUpdate: self =>
        });
    });

    const updateContent = (currentRoller) => {
        const texts = [...document.getElementsByClassName("slot-industries__text-item-industries")];
        let attrib;
        texts.forEach(text => {
            let el = document.querySelector(`[data-slot-industries=${attrib}]`);
            attrib = text.getAttribute("data-slot-industries");
            if (currentRoller === attrib) {
                text.classList.add("active");
                [...text.children].forEach(kid => {
                    if (kid.classList.contains("slot-industries__text-industries--description-industries")) {
                        gsap.to(kid, {
                            height: "auto",
                            duration: 0.5,
                            ease: "power1.inOut"
                        });
                    }
                });
            } else {
                text.classList.remove("active");
                [...text.children].forEach(kid => {
                    if (kid.classList.contains("slot-industries__text-industries--description-industries")) {
                        gsap.to(kid, {
                            height: 0,
                            duration: 0.5,
                            ease: "power1.inOut"
                        });
                    }
                });
            }
        });
    }


// https://codepen.io/GreenSock/pen/GqKrxG
// https://codepen.io/StephenScaff/pen/oLBqmw
    function setRoller(newRoller) {
        if (newRoller !== currentRoller) {
            // gsap.to(currentRoller, { autoAlpha: 0})
            currentRoller.querySelector("video").pause();
            newRoller.querySelector("video").play();
            gsap.to(currentRoller, {autoAlpha: 0, duration: 1});
            gsap.to(newRoller, {autoAlpha: 1, duration: .5});
            currentRoller = newRoller;
        }
    }
}
const animationOnScroll = () => {
    const revealContent = document.querySelectorAll(".fadeInUp");
    revealContent.forEach(container => {
        [...container.children].forEach(element => {
            gsap.fromTo(element, {
                autoAlpha: 0,
                y: 50
            }, {
                scrollTrigger: {
                    trigger: element,
                    once: true,
                    start: "top 75%"
                },
                duration: 1,
                autoAlpha: 1,
                y: 0
            });
        });
    });
}
const bgHandling = () => {
    const backgroundAnimationIntro = document.getElementById("bgvid"),
        backgroundAnimationLoop = document.getElementById("bgvidLoop");
    backgroundAnimationIntro.addEventListener("ended", () => {
        gsap.set(backgroundAnimationIntro, {zIndex: "-2"});
        gsap.set(backgroundAnimationLoop, {zIndex: "-1"});
        backgroundAnimationLoop.play();
    });
}
const featureMovieAnimation = () => {
    const windowHeight = window.innerHeight,
        featureHeight = feature.offsetHeight,
        space = windowHeight - featureHeight;
    let ratio, startTop, startBottom, endTop, endBottom;
    if (navigator.userAgent.includes("Mac")) {
        ratio = 1.33;
        startTop = 25;
        endTop = 49;
        startBottom = 51;
        endBottom = 75;
    } else {
        ratio = windowHeight / featureHeight;
        startTop = 5;
        endTop = 45;
        startBottom = 55;
        endBottom = 95;
    }

    ScrollTrigger.create({
        trigger: ".feature-movie",
        animation: gsap.to(feature, {scale: ratio}),
        start: `${startTop}% center`,
        end: `${endTop}% center`,
        scrub: 1,
        onEnter: () => feature.play(),
        immediateRender: false,
    });
    ScrollTrigger.create({
        trigger: ".feature-movie",
        animation: gsap.to(feature, {scale: 1}),
        start: `${startBottom}% center`,
        end: `${endBottom}% center`,
        onLeave: () => feature.pause(),
        onEnterBack: () => feature.play(),
        scrub: 1,
        immediateRender: false,
    });
    ScrollTrigger.create({
        trigger: ".feature-movie",
        animation: gsap.to(feature, {scale: 1}),
        start: `99% center`,
        end: `100% center`,
        onEnter: () => feature.pause(),
        scrub: 1,
        immediateRender: false,
    });
}

const showreel = document.getElementById("showreel");
showreel.addEventListener("click", e => handleBtnAndVid(e))
const handleShowreel = () => {
    const feature = document.getElementById("feature");
    const lb = document.querySelector(".lb");

    // Window & feature item calc
    let isFull = feature.getAttribute("full");
    feature.addEventListener("click", (e) => {
        isFull = feature.getAttribute("full");

        if (isFull === "false") {
            if (feature.webkitEnterFullScreen) {
                feature.webkitEnterFullScreen();
                feature.muted = false;
            }else{
                feature.play();
                feature.requestFullscreen();
                feature.muted = false;
            }
        }
    });

    feature.setAttribute("full", "false");
    feature.addEventListener("fullscreenchange", () => {
        isFull = feature.getAttribute("full");
        if (lb) {
            lb.remove();
        }
        if (isFull === "false") {
            feature.setAttribute("full", "true");
            feature.muted = false;
            feature.volume = 1;
            feature.currentTime = 0;
            document.querySelector("body").style.cursor = "unset";
            feature.setAttribute("controls", "controls");
        } else if (isFull === "true") {
            feature.setAttribute("full", "false");
            feature.muted = true;
            feature.volume = 0;
            document.querySelector("body").style.cursor = "none";
            feature.removeAttribute("controls");
        }
    });
}
const handleAutoStart = () => {
    let items = document.querySelectorAll(".selected__item--media");
    if (swiper.previousIndex >= 0) {
        if (!items[swiper.previousIndex].paused) {
            items[swiper.previousIndex].pause();
        }
        if (!items[swiper.previousIndex].paused) {
            items[swiper.activeIndex + 1].pause();
        }
        if (!items[swiper.previousIndex].paused) {
            items[swiper.activeIndex - 1].pause();
        }
    }
}
const handleBtnAndVid = (e) => {
    if (e.target.nodeName === "svg") {
        const targetData = e.target.getAttribute("data-video");
        if (targetData) {
            const targetVid = document.getElementById(targetData);
            e.target.style.display = "none";
            if (targetVid.webkitEnterFullScreen) {
                targetVid.webkitEnterFullScreen();
            } else {
                targetVid.play();
                targetVid.requestFullscreen();
            }
        }
    }

    if (e.target.nodeName === "VIDEO") {
        // if (e.target.nextElementSibling.nodeName === "svg") {
        //     e.target.nextElementSibling.style.display = "none";
        // }

        if (e.target.webkitEnterFullScreen) {
            e.target.webkitEnterFullScreen();
        } else {
            e.target.play();
            e.target.requestFullscreen();
        }
    }
}

const runVid = e => {
    handleBtnAndVid(e);
    handleAutoStart();
}

window.addEventListener("keydown", e => {
    if (e.key === "Escape") {
        if (document.body.classList.contains("auto-cursor")) {
            document.body.classList.remove("auto-cursor");
        }
    }
});

const handleSlider = e => {
    e.preventDefault();
    if (e.target.closest.nodeName === "video") {

        e.target.closest("video").setAttribute("data-full", "true");
        e.target.closest("video").play();
        e.target.closest("video").muted = false;
    }

    if (document.fullscreenElement) {
        if (document.exitFullscreen) {
        document.exitFullscreen()
            .then(() => {
                if (e.target.nodeName === "VIDEO") {
                    e.target.closest("video").pause();
                }
                console.log("Document Exited from Full screen mode")
            })
            .catch((err) => console.error(err));
        }
        [...document.querySelectorAll("video")].forEach(video => {
            video.setAttribute("data-full", "false");
            video.closest("video").pause();
        });
        document.body.classList.remove("auto-cursor");

    }else if(document.webkitCurrentFullScreenElement){
        if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
                .then(() => {
                    if (e.target.nodeName === "VIDEO") {
                        e.target.closest("video").pause();
                    }
                    console.log("Document Exited from Full screen mode")
                })
                .catch((err) => console.error(err));
            }
            [...document.querySelectorAll("video")].forEach(video => {
                video.setAttribute("data-full", "false");
                video.closest("video").pause();
            });
            document.body.classList.remove("auto-cursor");
    }else {
        e.target.closest("video").play();
        e.target.closest("video").volume = 1;
        e.target.closest("video").muted = false;
        document.body.classList.add("auto-cursor");
    }

    let item = e.currentTarget;
    const windowW = window.innerWidth;
    const windowH = window.innerWidth;
    const vidW = item.clientWidth;
    const vidH = item.clientWidth;
    runVid(e);
    if (item.className === "selected__item--media" && item.nodeName === "IMG") {
        const img = `<img class="lb__img" alt="${item.alt}" src="${item.src}" />`;
        const modal = document.createElement("div");
        modal.classList.add("lb");
        modal.innerHTML = img;
        document.body.appendChild(modal);
        document.body.addEventListener('keyup', (e) => {
            e.preventDefault();
            // console.log(e.keyCode);
            if (e.key === "Escape") {
                modal.remove();
            }
        });
        modal.addEventListener('click', function () {
            setTimeout(() => {
                modal.remove();
            }, 100);
        })
    }
    if (item.className === "selected__item--wrapper") {
        if (navigator.userAgent.includes("Mac") && onDesktop) {
            if (item.webkitEnterFullScreen) {
                item.webkitEnterFullScreen();
            }
        }
        item = item.querySelector("video");
        let isFull = item.getAttribute("data-full");
        if (navigator.userAgent.includes("Mac") && !onDesktop) {
            const showreel = `<video class="lb__img " src="${item.src}" />`;
            const modal = document.createElement("div");
            modal.classList.add("lb");
            modal.classList.add("ios");
            modal.innerHTML = showreel;
            document.body.appendChild(modal);
            item.play();
            document.body.addEventListener('keyup', (e) => {
                e.preventDefault();
                // console.log(e.keyCode);
                if (e.key === "Escape") {
                    modal.remove();
                }
            });
            modal.addEventListener('click', function () {
                setTimeout(() => {
                    modal.remove();
                }, 100);
            });

        } else {
            if (isFull === "false") {
                if (!navigator.userAgent.includes("Mac")) {
                    item.requestFullscreen();

                } else {
                    if (item.webkitEnterFullScreen) {
                        item.webkitEnterFullScreen();
                    }
                }
            }

        }
    }
}

document.addEventListener('keydown', evt => {
    if (evt.key === 'Escape') {
        [...document.querySelectorAll("video")].forEach(video => {
            video.setAttribute("data-full", "false");
            video.pause();
        });
    }
});

// window.addEventListener('keydown', evt => {
//     if (evt.key === 'Escape') {
//     }
// });
// [...document.querySelectorAll("video")].forEach(video => {
//     video.addEventListener('keydown', e => {
//     e.preventDefault();
//     }, true)
// });


const handleSelected = () => {
    // slider / horizontal scrolling
    let sliderItems = gsap.utils.toArray('.selected__item');

    // gsap.set(".selected__container", {
    //     width: (sliderItems.length + 1) * 100 + "%",
    // })

    // gsap.to(sliderItems, {
    //     xPercent: -100 * (sliderItems.length - 1),
    //     ease: 'none',
    //     scrollTrigger: {
    //         trigger: '.selected__container',
    //         pin: true,
    //         scrub: 1,
    //         snap: 1 / (sliderItems.length - 1),
    //         start: "top top",
    //         // base vertical scrolling on how wide the container is so it feels more natural.
    //         end: () => '+=' + document.querySelector('.selected__container').offsetWidth,
    //     },
    // });

    // sliderItems.forEach((item, i) => {
    //     ScrollTrigger.create({
    //         trigger: item,
    //         start: 'top top-=' + (item.offsetLeft - window.innerWidth / 2) * (document.querySelector(".selected__container").offsetWidth / (item.offsetWidth * (sliderItems.length - 1))),
    //         end: '+=' + item.offsetWidth * (document.querySelector(".selected__container").offsetWidth / (item.offsetWidth * (sliderItems.length - 1))),
    //         toggleClass: {targets: item, className: "active"},
    //         onToggle: async () => {
    //             if (!item.classList.contains("active")) {
    //                 try {
    //                     await item.querySelector("video").pause();
    //                 } catch (e) {
    //                     console.log(e)
    //                 }
    //             } else {
    //                 try {
    //                     if (!navigator.userAgent.includes("Mac") && !onDesktop) {
    //                         await item.querySelector("video").play();
    //                     }
    //                 } catch (e) {
    //                     console.log(e)
    //                 }
    //             }
    //
    //         }
    //     });
    // });
    document.querySelector(".selected__container").addEventListener("click", handleSlider);
    document.querySelector(".selected__container").addEventListener("fullscreenchange", (e) => {

        const vidH = e.target.offsetHeight;
        const windowH = window.innerHeight;


        let isFull = e.target.getAttribute("data-full");

        if (!navigator.userAgent.includes("Mac") && onDesktop) {
            if (isFull === "false") {
                e.target.setAttribute("data-full", "true");
                e.target.muted = false;
                e.target.volume = 1;
                document.querySelector("body").classList.add("movie-on");
                document.querySelector("body").style.cursor = "auto";
                e.target.setAttribute("controls", "controls");
            } else if (isFull === "true") {
                e.target.setAttribute("data-full", "false");
                document.querySelector("body").classList.remove("movie-on");
                e.target.muted = true;
                e.target.volume = 0;
                document.querySelector("body").style.cursor = "none";
                e.target.removeAttribute("controls");
            }
        }
        if (!onDesktop) {
            // if (vidH !== windowH) {
            //     e.target.pause();
            // }
            if (!document.fullscreenElement) {
                e.target.pause();
            }
        }
    });
}


const mobileNavigation = () => {
    const tl = gsap.timeline();
    const animatedChildren = (direction = "open") => {
        if (direction === "open") {
            gsap.set(".navigation", {height: "auto"});
            tl.to(".navigation__wrapper", {
                y: 0,
                duration: .5,
                ease: "power4.out",
            })
                .to(".navigation__list", {
                    y: 0,
                    duration: .5,
                    ease: "slow(0.7, 0.7, false)",
                });
        } else if (direction === "close") {
            tl.to(".navigation__list", {
                y: "-100%",
                duration: .5,
                ease: "slow(0.7, 0.7, false)",
            })
                .to(".navigation__wrapper", {
                    y: "-100%",
                    duration: .5,
                    ease: "power4.out",
                }).set(".navigation", {height: 0})
        }
    }
    document.querySelector(".navigation__btn").addEventListener("click", e => toggle(e));
    const toggle = (e) => {
        let element = e.target.closest("button");
        if (element.classList.contains("active")) {
            animatedChildren("close");
            element.classList.remove("active");
        } else {
            element.classList.add("active");
            animatedChildren("open");
        }
    }
    document.querySelector(".navigation__list").addEventListener("click", e => {
        if (e.target.nodeName === "A") {
            animatedChildren("close");
            document.querySelector(".navigation__btn").classList.remove("active");
        }
    });
}

function duplicateChildNodes(parentId) {
    var parent = document.getElementById(parentId);
    NodeList.prototype.forEach = Array.prototype.forEach;
    var children = parent.childNodes;
    children.forEach(function (item) {
        var cln = item.cloneNode(true);
        parent.appendChild(cln);
    });
};
const handleSlots = () => {
    const slotServicesHeadings = document.querySelectorAll(".slot__text-item");
    const slotIndustriesHeadings = document.querySelectorAll(".slot-industries__text-item-industries");
    const services = document.querySelector(".services");
    const industries = document.querySelector(".industries");
    const clickableSlots = (slots, elem) => {
        const elemH = elem.offsetHeight;
        [...slots].forEach((heading, i) => {
            heading.addEventListener("click", e => {
                if (i === slots.length - 1) {
                    window.scrollTo(0, elem.offsetTop + (elemH - elemH) + i * (window.innerHeight));
                } else {
                    window.scrollTo(0, elem.offsetTop + (elemH * .4) + i * (window.innerHeight));
                }
            });
        });
    }
    clickableSlots(slotServicesHeadings, services);
    clickableSlots(slotIndustriesHeadings, industries);
}

const handleSectionVids = () => {
    const slotServicesHeadings = document.querySelectorAll(".slot__text-item");
    const slotIndustriesHeadings = document.querySelectorAll(".slot-industries__text-item-industries");
    const servicesVideos = document.querySelectorAll(".services .roller");
    const industriesVideos = document.querySelectorAll(".industries .roller-industries");
    const services = document.querySelector(".services");
    const industries = document.querySelector(".industries");
    const initOnEnterView = (slot, vid) => {
        const video = vid[0].querySelector("video");
        const offsetTop = window.scrollY;
        if (offsetTop >= (slot.offsetTop - slot.offsetHeight) && offsetTop <= (slot.offsetTop)) {
            if (video.paused) {
                video.play();
            }
        }
    }

    const clickableSlots = (slots, videos) => {
        [...slots].forEach((heading, i) => {
            heading.addEventListener("click", e => {
                let attrib = heading.getAttribute("data-slot");
                let el = document.querySelector(`[data-slot=${attrib}]`);
                let video = document.getElementById(`${attrib}`);
                video.classList.add("active");
                [...slots].forEach((heading) => {
                    heading.classList.remove("active");
                    [...heading.children].forEach(kid => {
                        if (kid.classList.contains("slot-description")) {
                            gsap.to(kid, {
                                height: "0",
                                duration: 0.5,
                                ease: "power1.inOut"
                            });
                        }
                    });
                });
                [...heading.children].forEach(kid => {
                    if (kid.classList.contains("slot-description")) {
                        gsap.to(kid, {
                            height: "auto",
                            duration: 0.5,
                            ease: "power1.inOut"
                        });
                    }
                });
                [...videos].forEach((video) => {
                    gsap.to(video, {autoAlpha: 0, duration: 1});
                    video.querySelector("video").pause();
                });

                const element = e.target.closest(".slot-item");
                if (!element.classList.contains("active")) {
                    element.classList.add("active");
                    gsap.to(video, {autoAlpha: 1, duration: 1});
                    video.querySelector("video").play();

                }
            });
        });
    }
    clickableSlots(slotServicesHeadings, servicesVideos);
    clickableSlots(slotIndustriesHeadings, industriesVideos);

    window.addEventListener("scroll", () => {
        initOnEnterView(services, servicesVideos);
        initOnEnterView(industries, industriesVideos);
    });
}

// window.addEventListener("scroll", () => {
//     const servicesSlots = document.querySelector(".slot").children;
//     const offsetLastItem = servicesSlots[servicesSlots.length - 1].offsetTop;
//     const offsetTop = window.scrollY;
//     const multiplier = servicesSlots.length * servicesSlots[servicesSlots.length - 1].offsetHeight;
//
//     if (offsetLastItem + multiplier < offsetTop) {
//         // text.classList.add("active");
//         console.log(servicesSlots[servicesSlots.length -1])
//         console.log(offsetTop);
//         console.log(offsetLastItem);
//     }
// });
const hideLogo = () => {
    const logo = document.querySelector(".navigation__logo");
    window.addEventListener("scroll", () => {
        if (window.scrollY >= 300) {
            if (!logo.classList.contains("hide")) {
                logo.classList.add("hide")
            }
        } else {
            if (logo.classList.contains("hide")) {
                logo.classList.remove("hide")
            }
        }
    })
}
const cursor = document.querySelector('.circle'),
    // follow = document.querySelector('.circle-follow'),
    vid = document.querySelector(".feature-movie__content"),
    onVidCursor = document.querySelector(".fullscreen-mask");

// window.addEventListener("click", () => {
//     console.log("klikam se")
//     cursor.classList.add("expand");
//     follow.classList.add("expand-follow")
//
//     setTimeout(() => {
//         cursor.classList.remove("expand");
//         follow.classList.remove("expand-follow");
//     }, 300);
// })

let posX = 0,
    posY = 0;

let mouseX = 0,
    mouseY = 0;

const moveCircle = (e) => {
    // console.log(e.target);
    gsap.to(cursor, .3, {
        x: e.clientX,
        y: e.clientY,
    })
    // gsap.to(follow, .7, {
    //     x: e.clientX,
    //     y: e.clientY,
    // })
}

const cursorPointer = (e) => {
    const t = e.target.nodeName;
    const c = e.target.className;
    if (t === "A") {
        gsap.to(cursor, .3, {
            scale: .5,
            background: "rgba(255,255,255, .7)"
        });
        // gsap.to(follow, .3, {
        //     scale: .35
        // });
    } else if (c === "feature-movie__content" || c === "selected__item--wrapper") {
        cursor.classList.add("on-vid");
    }
}
const cursorUnPointer = (e) => {
    const t = e.target.nodeName;
    const c = e.target.className;
    if (t !== "A" && c !== "feature-movie__content" && c !== "selected__item--wrapper") {
        cursor.classList.remove("on-vid");
        gsap.to(cursor, .3, {
            scale: 1,
            background: "transparent",
        });
        // gsap.to(follow, .3, {
        //     scale: 1
        // });
    }
    // else if (c === "feature-movie__content") {
    //     gsap.to(cursor, .3, {
    //         background: "black",
    //         scale: 3,
    //     });
    //     cursor.classList.add("on-vid");
    // }

}

const vidCursor = e => {
    if (e.target.className === "selected__item--wrapper") {
        cursor.classList.add("on-vid");
        gsap.to(cursor, .3, {
            scale: 1.5,
            background: "black",
        });
    }
}
const removeVidCursor = e => {
    if (e.target.className === "selected__item--wrapper") {
        cursor.classList.remove("on-vid");
        gsap.to(cursor, .3, {
            scale: 1,
            background: "transparent",
        });
    }
}
document.querySelector(".selected__container").addEventListener("mousemove", vidCursor);
document.querySelector(".selected__container").addEventListener("mouseover", vidCursor);
document.querySelector(".selected__container").addEventListener("mouseout", removeVidCursor);


window.addEventListener("mousemove", moveCircle);
document.addEventListener("mousemove", cursorPointer);
document.addEventListener("mousemove", cursorUnPointer);

vid.addEventListener("mousedown", e => {
    onVidCursor.classList.add("active");
});
vid.addEventListener("mouseup", e => {
    onVidCursor.classList.remove("active");
});
vid.addEventListener("mouseleave", e => {
    onVidCursor.classList.remove("active");
    cursor.classList.remove("on-vid");
    gsap.to(cursor, .3, {
        scale: 1,
        background: "transparent",
    });
});
vid.addEventListener("mouseenter", e => {
    cursor.classList.add("on-vid");
    gsap.to(cursor, .3, {
        scale: 1.5,
        background: "black",
    });
});
// const feature = c
const isNav = document.querySelector("nav");
const onDesktop = window.innerWidth >= 800;
document.querySelector("section.feature-movie").classList.add("safari");
revealHeading();

const bgVid = document.getElementById("bgvid"),
    bgVidSrc = "ThePack_AnimationLogo_5s-intro_comp_1080x1080_rec709-24_Grain_v030.mp4";

const bgVidLoop = document.getElementById("bgvidLoop"),
    bgVidLoopSrc = "ThePack_AnimationLogo_10s-loop_comp_1080x1080_rec709-24_Grain_v030.mp4";

const handlePaths = (video, path) => {
    video.querySelectorAll("source").forEach(async src => {
        if (src.getAttribute("type") === "video/mp4") {
            await video.setAttribute("src", `./assets/video/logo_animations/${path}`);
            await video.play();
        } else if (src.getAttribute("type") === "video/webm") {
            video.setAttribute("src", `./assets/video/logo_animations/${path}`);
        }
    });
}

const vidOnIPhone = (vid) => {
    const showreel = `<video class="lb__img " src="${feature.children[0].src}" />`;
    const modal = document.createElement("div");
    modal.classList.add("lb");
    modal.classList.add("ios");
    modal.innerHTML = showreel;
    document.body.appendChild(modal);
    vid.muted = false;
    vid.play();
    document.body.addEventListener('keyup', (e) => {
        e.preventDefault();
        // console.log(e.keyCode);
        if (e.key === "Escape") {
            modal.remove();
        }
    });
    modal.addEventListener('click', function () {
        setTimeout(() => {
            modal.remove();
        }, 100);
    });
};

const handleIPhoneIos = () => {
    if (!onDesktop) {
        bgVid.remove();
        bgVidLoop.remove();
        const bg = document.createElement("img");
        bg.classList.add("ios-iphone-bg");
        bg.setAttribute("src", "./assets/img/animationlogo_1120.jpg");
        document.body.appendChild(bg);
    }

    const feature = document.getElementById("feature");
    feature.addEventListener("click", () => {
        if (feature.className === "feature-movie__content" && feature.nodeName === "VIDEO") {
            vidOnIPhone(feature);
        }
    });
}

bgHandling();
if (onDesktop) {
    navigationAnimation();
    featureMovieAnimation();
    handleShowreel();
} else {
    if (navigator.userAgent.includes("Mac")) {
        handleIPhoneIos();
    } else {
        handleShowreel();
        handlePaths(bgVid, bgVidSrc);
        handlePaths(bgVidLoop, bgVidLoopSrc);
    }
}

window.addEventListener("load", () => {

    initMap();
    handleSelected();
    // handleSlots();
    activeNav();
    handleSectionVids();
    swiper.on("slideChange", () => handleAutoStart())
    if (!onDesktop) {
        mobileNavigation();
        hideLogo();

        const vids = document.querySelectorAll(".swiper video")
        const showreelVid = document.querySelectorAll(".feature-movie video");
        const allVids = [...vids, ...showreelVid];

        allVids.forEach(vid => {
            // console.log(vid)
            let attr = vid.getAttribute("poster");
            let getIndex = attr.lastIndexOf("/");
            let path = attr.substring(getIndex);
            attr = attr.substring(0, getIndex);
            let mobilePath = `${attr}/mobile${path}`;
            vid.setAttribute("poster", mobilePath);
        })

    }
    animationOnScroll();


    // duplicateChildNodes("ticker");
    talents();
    // slotMachine();
    // slotMachineIndustries();
});

function initMap() {
    const flanders = document.getElementById("flanders"),
        brussels = document.getElementById("brussels"),
        wallonia = document.getElementById("wallonia");

    let zoom;
    if (onDesktop) {
        zoom = 9;
    } else {
        zoom = 8;
    }

    const styles = {
        dark: [
            {
                elementType: "geometry",
                stylers: [
                    {
                        color: "#212121"
                    }
                ]
            },
            {
                elementType: "labels.icon",
                stylers: [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#212121"
                    }
                ]
            },
            {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                featureType: "administrative.country",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e"
                    }
                ]
            },
            {
                featureType: "administrative.land_parcel",
                stylers: [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#bdbdbd"
                    }
                ]
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#181818"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#1b1b1b"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#2c2c2c"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#8a8a8a"
                    }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#373737"
                    }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#3c3c3c"
                    }
                ]
            },
            {
                featureType: "road.highway.controlled_access",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#4e4e4e"
                    }
                ]
            },
            {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161"
                    }
                ]
            },
            {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#000000"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#3d3d3d"
                    }
                ]
            }
        ],
    };
    const iconMarker = "./assets/svg/logo.svg";
    const flandersCoords = {lat: 51.134280, lng: 4.478700};
    const walloniaCoords = {lat: 50.45683864990471, lng: 3.926416311697875};
    const brusselsCoords = {lat: 50.859310, lng: 4.337240};
    const map = new google.maps.Map(document.getElementById("map"), {
        zoom,
        center: brusselsCoords,
        icon: iconMarker,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
    });

    flanders.addEventListener("click", e => {
        map.setZoom(12);
        map.setCenter(flandersCoords);
        window.scrollTo(0,document.body.scrollHeight);
    });
    brussels.addEventListener("click", e => {
        map.setZoom(12);
        map.setCenter(brusselsCoords);
        window.scrollTo(0,document.body.scrollHeight);
    });
    wallonia.addEventListener("click", e => {
        map.setZoom(12);
        map.setCenter(walloniaCoords);
        window.scrollTo(0,document.body.scrollHeight);
    });

    const markers = [new google.maps.Marker({
        position: flandersCoords,
        map: map,
        icon: iconMarker,
    }), new google.maps.Marker({
        position: walloniaCoords,
        map: map,
        icon: iconMarker,
    }), new google.maps.Marker({
        position: brusselsCoords,
        map: map,
        icon: iconMarker,
    })];
    map.setOptions({styles: styles.dark});
}

// const slotMachine = () => {
//     let rollers = gsap.utils.toArray(".roller"),
//         currentRoller = rollers[0];
//     console.log(rollers.length)
//
//     gsap.defaults({overwrite: 'auto', duration: 0.3});
//     gsap.set(".slot", {height: (rollers.length * 100) + "vh"});
//
//     rollers.forEach((roller, i) => {
//         console.log(roller.offsetTop)
//         ScrollTrigger.create({
//             start: () => (i - 0.5) * innerHeight,
//             end: () => (i + 0.5) * innerHeight,
//             onToggle: self => self.isActive && setRoller(roller),
//             markers: true,
//         });
//     });
// }
//
